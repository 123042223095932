import { TouchEvent, useEffect, useRef, useState } from 'react';
import { isMobile, transNum } from '../../../../component/function/function';
import { FilterModal, FilterModal2, FilterModal3 } from './FilterModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuctionService from '../../../../service/auction/Auction';
import useDidMountEffect from '../../../../hooks/UseDidMountEffect';
import { useDispatch } from 'react-redux';
import { LoadingDot } from '../../../../store/loadingSlice';
import { useAuth } from '../../../../utilities/auth';
import { SideBarRecentSearchContentClose } from '../../../../asset/styles/mobile/aiFraming/AIFarmingMap';
import { Helmet } from 'react-helmet';

import { PointAlert2 } from '../../../../component/common/pointAlert2';
import WarnPoint from '../../../../component/common/warnPoint';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { clickGoToDetail } from '../../../../hackler';
import Item from './AuctionItem';
import { set } from 'lodash';
import ReportService from '../../../../service/report/reportApi';
import TopBanner from '../../../../component/banner/TopBanner';

const Auction = ({
  open,
  panto,
  setIsSales,
  setClose,
  close,
  isFarm,
  setIsFarm,
  mobileType,
  selectedItem,
  setSelectedItem,
  houseReportIdx,
  setHouseReportIdx,
  setType,
  type,
}: any) => {
  const [tempAddress, setTempAddress] = useState('');
  const [selectedAuctionNumber, setSelectedAuctionNumber] = useState('');
  const [category, setCategory] = useState('tab1');
  const [tab, setTab] = useState('tab1');
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const tab3 = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [left, setLeft] = useState(16);
  const [width, setWidth] = useState(93);
  const dispatch = useDispatch();
  const [orderby1, setOrderby1] = useState('등록일 나중순');
  const [orderby, setOrderby] = useState('입찰일 나중순');
  const [loading, setLoading] = useState(false);
  const [onModal, setOnModal] = useState(false);
  const [onModal2, setOnModal2] = useState(false);
  const [onModal3, setOnModal3] = useState(false);
  const [modalAddress, setModalAddress] = useState('');
  const [saleIdx, setSaleIdx] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const temp = useRef<HTMLImageElement>(null);
  const [dragging, setDragging] = useState(false);
  const [clickPoint, setClickPoint] = useState(0);
  const [scrollTop, setScrollTop] = useState(-165);
  const [scrollTop2, setScrollTop2] = useState(0);
  const [scrollTop3, setScrollTop3] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const specialRef = useRef<HTMLDivElement>(null);
  const [reset, setReset] = useState(false);
  const [closeTop, setCloseTop] = useState(false);
  const [onDetail, setOnDetail] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [move, setMove] = useState(false);
  const [point, setPoint] = useState(0);
  const [onPointAlert, setOnPointAlert] = useState(false);
  const locationHook = useLocation();

  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList]: any = useState([
    // {
    //   address: "",
    //   area: 0,
    //   buildingArea: null,
    //   buildingPyeong: null,
    //   endDate: "",
    //   estimated: 0,
    //   img: "",
    //   keyword: "",
    //   likeCount: 0,
    //   price: 0,
    //   pyeong: 0,
    //   regTime: "",
    //   roadSurface: "",
    //   salesIdx: 0,
    //   startDate: "",
    //   views: 0,
    //   category: "",
    //   isFarm: "",
    //   auctionIdx: 0,
    //   auctionNumber: "",
    //   court: "",
    //   bidDate: "",
    //   appraisedValue: 0,
    //   lowestPrice: 0,
    //   bidCount: 0,
    //   dynamicPrice: 8958,
    // },
  ]);
  const [region, setRegion]: any = useState([]);
  const obj = {
    '등록일 나중순': 'AD',
    '등록일 최근순': 'AA',
    '희망가 높은순': 'BD',
    '희망가 낮은순': 'BA',
    '좋아요 높은순': 'CD',
    '좋아요 낮은순': 'CA',
  };
  const obj2 = {
    '입찰일 나중순': 'AD',
    '입찰일 최근순': 'AA',
    '유찰횟수 높은순': 'BD',
    '유찰횟수 낮은순': 'BA',
    '감정가 높은순': 'CD',
    '감정가 낮은순': 'CA',
    '최저가 높은순': 'DD',
    '최저가 낮은순': 'DA',
  };

  const firstGetList = async () => {
    try {
      let temp = { S: tab1, A: tab2, P: tab3 };
      const res = await AuctionService.list(
        type,
        isFarm,
        region.join(',') || '',
        decodeURI(location.search.split('=')[1] || ''),
        type === 'S'
          ? obj[orderby1 as '등록일 나중순']
          : obj2[orderby as '입찰일 나중순'],
        10,
        0
      );

      if (!res.totalCount) {
        let arr = ['S', 'A', 'P'];
        arr.splice(arr.indexOf(type), 1);

        const res1 = await AuctionService.list(
          arr[0],
          isFarm,
          region.join(',') || '',
          decodeURI(location.search.split('=')[1] || ''),
          type === 'S'
            ? obj[orderby1 as '등록일 나중순']
            : obj2[orderby as '입찰일 나중순'],
          10,
          0
        );
        if (res1.totalCount) {
          setType(arr[0]);
          if (temp[arr[0] as 'A'].current) {
            setLeft(temp[arr[0] as 'A'].current?.offsetLeft || 0);
            setWidth(temp[arr[0] as 'A'].current?.offsetWidth || 0);
          }
          return;
        }

        const res2 = await AuctionService.list(
          arr[1],
          isFarm,
          region.join(',') || '',
          decodeURI(location.search.split('=')[1] || ''),
          type === 'S'
            ? obj[orderby1 as '등록일 나중순']
            : obj2[orderby as '입찰일 나중순'],
          10,
          0
        );
        if (res2.totalCount) {
          setType(arr[1]);
          if (temp[arr[1] as 'A'].current) {
            setLeft(temp[arr[1] as 'A'].current?.offsetLeft || 0);
            setWidth(temp[arr[1] as 'A'].current?.offsetWidth || 0);
          }
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    firstGetList();
  }, [location]);

  const getList = async () => {
    try {
      setLoading(true);
      setList([]);
      dispatch(LoadingDot('A'));
      const res = await AuctionService.list(
        type,
        isFarm,
        region.join(',') || '',
        decodeURI(location.search.split('=')[1] || ''),
        type === 'S'
          ? obj[orderby1 as '등록일 나중순']
          : obj2[orderby as '입찰일 나중순'],
        10,
        0
      );
      setPage(0);
      setList(res.rows);
      listRef.current?.scrollTo(0, 0);
      if (res.rows) {
        setScrollTop(-165);
      } else {
        setScrollTop(0);
      }
      setTotalCount(res.totalCount);
      dispatch(LoadingDot('N'));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(LoadingDot('N'));
      console.log(err);
    }
  };

  const [noDouble, setNoDouble] = useState(false);
  const getList2 = async () => {
    try {
      if (totalCount === list.length && loading) {
        return;
      }
      if (list.length < 10) {
        return;
      }
      if (noDouble) {
        return;
      }
      dispatch(LoadingDot('A'));
      setLoading(true);
      setNoDouble(true);
      const res = await AuctionService.list(
        type,
        isFarm,
        region.join(',') || '',
        decodeURI(location.search.split('=')[1] || ''),
        type === 'S'
          ? obj[orderby1 as '등록일 나중순']
          : obj2[orderby as '입찰일 나중순'],
        10,
        page * 10
      );
      if (res.rows.length === 0) {
        alert('마지막 페이지입니다.');
      } else {
        setLoading(false);
      }
      setList((prev: any) => [...prev, ...res.rows]);
      setNoDouble(false);
      dispatch(LoadingDot('N'));
    } catch (err) {
      setNoDouble(false);
      setLoading(false);
      dispatch(LoadingDot('N'));
      console.log(err);
    }
  };
  useDidMountEffect(() => {
    if (page) {
      getList2();
    }
  }, [page]);

  useEffect(() => {
    setCloseTop(false);
    if (setClose) {
      setClose(false);
    }
    getList();
  }, [type, isFarm, location.search, region, orderby, orderby1]);

  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };

  const getReportIdx = async () => {
    dispatch(LoadingDot('Y'));
    try {
      const data = {
        type: type,
        value:
          type === 'S'
            ? selectedItem?.address || ''
            : selectedItem?.auctionNumber || '',
      };
      const result = await AuctionService.save(data);
      dispatch(LoadingDot('N'));
      setHouseReportIdx(result.reportIdx);
      if (isMobile(window.innerWidth)) {
        navigate(`/auction/report/${result.reportIdx}/${type}`, {
          state: {
            type: type,
            value:
              type === 'S'
                ? selectedItem?.address || ''
                : selectedItem?.auctionNumber || '',
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch(LoadingDot('N'));
    }
  };

  useEffect(() => {
    CheckPoint();
  }, []);
  const checkReport = async () => {
    try {
      const result = await ReportService.check(
        type === 'S' ? 'SS' : type === 'A' ? 'SA' : 'SP',
        type === 'S' ? tempAddress : selectedAuctionNumber
      );

      if (result.isExist === false) {
        setOnPointAlert(true);
      } else {
        showReport();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useDidMountEffect(() => {
    if (window.innerWidth >= 1024) {
      setClose(false);
    }
    checkReport();
  }, [selectedItem]);

  const showReport = async () => {
    await getReportIdx();
    clickGoToDetail(locationHook.pathname.split('/')[1]);
    if (selectedItem) {
      panto(selectedItem.address);

      // : open(reportNumber, type);
      // !isMobile(window.innerWidth) && setClose(!close); // setClose는 auction에서 같은곳을 클릭했을 때 정보가 안열리게 하는 이슈를 해결
    }
  };
  const ClickSameAddress = () => {
    if (tempAddress === selectedItem?.address) {
      setClose(!close);
    }
  };
  const handleTouchDownEvent = (e: TouchEvent<HTMLDivElement>) => {
    setDragging(true);
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.className =
        'fixed bottom-0 bg-FFFFFF flex-column w-100per border-top-radius-27 w-100per z-1';
      setClickPoint(e.touches[0].pageY); // clickPoint는 처음 클릭한 지점
      setScrollTop3(scrollTop);
      // setScrollTop(containerRef.current.offsetTop); // 스크롤움직인 거리, 처음은 0
    }
    setMove(true);
  };

  const handleTouchMoveEvent = (e: TouchEvent<HTMLDivElement>) => {
    setMove(false);
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.stopPropagation();
    if (containerRef.current) {
      setScrollTop(scrollTop3 + clickPoint - e.touches[0].pageY);
      setScrollTop2(e.touches[0].pageY);
    }
  };

  const handleMouseEventEnd = (e: TouchEvent) => {
    if (move) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.className =
        'fixed bottom-0 bg-FFFFFF flex-column w-100per border-top-radius-27 w-100per z-1 transition';
      setScrollTop((prev) => {
        if (!reset) {
          const temp =
            clickPoint < scrollTop2
              ? (containerRef.current?.offsetHeight || 0) * -1 + 85
              : list.length
              ? window.innerHeight -
                (containerRef.current?.offsetHeight || 0) -
                70
              : 0;

          setReset(true);

          if (clickPoint > scrollTop2) {
            setTimeout(() => setCloseTop(true), 500);
          }

          return temp;
        } else {
          setReset(false);
          setCloseTop(false);
          return -165;
        }
      });
    }
  };

  const { isLogin } = useAuth();
  const handleScroll2 = async () => {
    const scrollHeight = listRef.current?.scrollHeight || 0;
    const scrollTop = listRef.current?.scrollTop || 0;
    const clientHeight = listRef.current?.clientHeight || 0;
    if (scrollTop + clientHeight >= scrollHeight - 3 && !loading) {
      setPage((prev) => prev + 1);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    listRef.current?.addEventListener('scroll', handleScroll2);
    return () => listRef.current?.removeEventListener('scroll', handleScroll2);
  }, []);
  if (window.navigator.userAgent === 'ReactSnap') {
    return <></>;
  }

  return (
    <div
      ref={containerRef}
      className={
        isMobile(window.innerWidth)
          ? 'fixed bottom-0 bg-FFFFFF flex-column w-100per border-top-radius-27 w-100per z-1 flex-column transition'
          : 'flex-column'
      }
      style={
        isMobile(window.innerWidth)
          ? {
              bottom: scrollTop,
              maxHeight: onDetail ? '100%' : 200,
            }
          : {}
      }
    >
      {onPointAlert ? (
        point >= 2000 ? (
          <PointAlert2
            point={point}
            setOnPointAlert={setOnPointAlert}
            getReportApi={showReport}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )}

      <FilterModal
        open={onModal}
        setOpen={setOnModal}
        orderby={type === 'S' ? orderby1 : orderby}
        setOrderby={type === 'S' ? setOrderby1 : setOrderby}
        type={type}
      />
      <FilterModal2
        open={onModal2}
        setOpen={setOnModal2}
        region={region}
        setRegion={setRegion}
        totalCount={totalCount}
      />
      <FilterModal3
        open={onModal3}
        setOpen={setOnModal3}
        region={region}
        setRegion={setRegion}
        totalCount={totalCount}
        modalAddress={modalAddress}
        saleIdx={saleIdx}
      />
      {isMobile(window.innerWidth) ? (
        <div
          onClick={() => {
            setOnDetail(!onDetail);
          }}
          style={{ paddingTop: '8px' }}
          className='flex-column center-y'
          onTouchStart={handleTouchDownEvent}
          onTouchEnd={handleMouseEventEnd}
          onTouchMove={handleTouchMoveEvent}
        >
          <img
            ref={temp}
            src={
              onDetail
                ? '/asset/images/arrow/ArrowBottom24.svg'
                : '/asset/images/arrow/ArrowTop24.svg'
            }
            className='hover'
            style={{ width: '24px', height: '24px' }}
            alt=''
          />
        </div>
      ) : (
        ''
      )}

      <div ref={specialRef}></div>
      {/* <input
  type="text"
  placeholder={
    isFarm === 'Y'
      ? '농지 주소를 입력하세요'
      : isFarm === 'N'
      ? '주택 주소를 입력하세요'
      : '주소를 입력하세요'
  }
  // Add any other attributes you need for the input element
/> */}
      {isLogin() && (
        <div className='padding-16 flex'>
          <Link
            to='https://transfarmer.notion.site/e3eb7cc33250494c96a32ec1c0d4b759'
            className='bg-primary-100 border-radius-8 padding-10-16 flex-row space-between w-100per center-y pointer'
            style={{ textDecoration: 'none' }}
          >
            <div className='flex-row gap-8 center-y'>
              <div
                className='bg-primary-500 border-radius-2 flex-center'
                style={{ padding: '3px 6px' }}
              >
                <div className='m-c1-12-b color-FFFFFF'>농촌 부동산 1번지</div>
              </div>
              <div className='m-b2-14-m color-dark-primary'>
                <span style={{ color: '#1E9B6B' }}>
                  지역 전담 파트너 중개사
                </span>{' '}
                신청
              </div>
            </div>
            <img src='/asset/images/arrow/ArrowRightBlack.svg' />
          </Link>
        </div>
      )}
      {!isMobile(window.innerWidth) ? (
        <>
          <TopBanner search />{' '}
          <div className='padding-8-16 gap-16 flex-row'>
            {[
              { title: '전체', value: '' },
              { title: '농지', value: 'Y' },
              { title: '주택', value: 'N' },
            ].map((el, idx) => {
              if (type === 'S' && el.title === '주택') {
                return '';
              }
              return (
                <div
                  key={idx}
                  onClick={() => {
                    setIsFarm(el.value);
                  }}
                  className='pointer flex-row gap-16 center-y'
                >
                  <div
                    className={`font-bold f-size-20 line-h-29 ${
                      isFarm === el.value
                        ? 'color-text-primary'
                        : 'color-text-tertiary'
                    }`}
                  >
                    {el.title}
                  </div>
                  {idx !== 2 ? (
                    <div
                      style={{
                        width: '1px',
                        height: '17px',
                        background: '#d9d9d9',
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div style={{ position: 'absolute', top: -117, width: '100%' }}>
          <TopBanner />
          <div
            className='padding-8-16 gap-16 flex-row bg-FFFFFF w-100per'
            style={{ borderTop: '1px solid #171e2610' }}
          >
            {[
              { title: '전체', value: '' },
              { title: '농지', value: 'Y' },
              { title: '주택', value: 'N' },
            ].map((el, idx) => {
              if (type === 'S' && el.title === '주택') {
                return '';
              }
              return (
                <div
                  key={idx}
                  onClick={() => {
                    setIsFarm(el.value);
                  }}
                  className='pointer flex-row gap-16 center-y'
                >
                  <div
                    className={`font-bold f-size-20 line-h-29 ${
                      isFarm === el.value
                        ? 'color-text-primary'
                        : 'color-text-tertiary'
                    }`}
                  >
                    {el.title}
                  </div>
                  {idx !== 2 ? (
                    <div
                      style={{
                        width: '1px',
                        height: '17px',
                        background: '#d9d9d9',
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!isMobile(window.innerWidth) ? (
        <div style={{ background: '#F0F0F0', height: '8px' }} />
      ) : (
        ''
      )}

      {closeTop ? (
        <div
          style={{
            background: '#F0F0F0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src='/asset/images/arrow/ArrowBottom24.svg'
            style={{ width: '16px', height: '16px' }}
            alt=''
          />
        </div>
      ) : (
        ''
      )}
      <div
        className={
          'gap-24 flex-row relative border-b-1-171E2610 ' +
          (closeTop ? 'padding-x-16' : 'padding-8-16-0 ')
        }
      >
        {[
          { title: '지자체 매물', value: 'S', ref: tab1 },
          { title: '경매', value: 'A', ref: tab2 },
          { title: '공매', value: 'P', ref: tab3 },
        ].map((el, idx) => (
          <div
            key={idx}
            ref={el.ref}
            onClick={() => {
              if (el.value === 'S') {
                setIsSales(true);
              } else {
                setIsSales(false);
              }
              if (el.value === 'S') {
                setIsFarm('');
              }
              setType(el.value);
              if (el.ref.current) {
                setLeft(el.ref.current.offsetLeft);
                setWidth(el.ref.current.offsetWidth);
              }
            }}
            onTouchStart={handleTouchDownEvent}
            onTouchEnd={handleMouseEventEnd}
            onTouchMove={handleTouchMoveEvent}
            className='flex-1 padding-y-8 center-x  pointer'
          >
            <div
              className={`font-bold f-size-18 line-h-26 ${
                type === el.value ? 'color-text-primary' : 'color-text-tertiary'
              }`}
            >
              {el.title}
            </div>
          </div>
        ))}

        <div
          className='absolute bg-000000 transition'
          style={{
            width: width + 'px',
            left: left + 'px',
            height: '2px',
            bottom: 0,
          }}
        />
      </div>
      <div className='auction-filter-tab-box'>
        <div
          className='auction-filter-tab'
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
            padding: '8px 0',
          }}
        >
          <div
            className={
              'border-radius-100 hover flex-row gap-5 ' +
              (region.length === 0 ? 'padding-6 ' : 'padding-6-16')
            }
            onClick={() => setOnModal2(true)}
            style={{
              border:
                region.length === 0 ? '1px solid #F0F0F0' : '1px solid #000000',
            }}
          >
            <img
              src={
                region.length === 0
                  ? '/asset/images/filter/Filter.svg'
                  : '/asset/images/filter/Filter2.svg'
              }
              style={{ width: '24px', height: '24px' }}
              alt=''
            />
            {region.length ? (
              <div className='font-medium f-size-16 line-h-24 no-wrap color-text-primary'>
                {region.length}
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className='padding-6-16 border-radius-100 hover'
            style={{
              border: '1px solid #F0F0F0',
              background: region.length === 0 ? '#171e26' : '',
            }}
            onClick={() => setRegion([])}
          >
            <div
              className={
                'font-medium f-size-16 line-h-24 color-FFFFFF no-wrap ' +
                (region.length === 0 ? 'color-FFFFFF' : 'color-text-tertiary')
              }
            >
              전국
            </div>
          </div>

          {[
            '경기',
            '강원',
            '충북',
            '충남',
            '전북',
            '전남',
            '경북',
            '경남',
            '부산',
            '대구',
            '세종',
            '광주',
            '서울',
            '울산',
            '대전',
            '제주',
          ].map((el, idx) => (
            <div
              key={idx}
              className='padding-6-16 border-radius-100 hover'
              style={{
                border: '1px solid #F0F0F0',
                background: region.includes(el) ? '#171e26' : '',
              }}
              onClick={() => {
                if (region.includes(el)) {
                  const idx = region.indexOf(el);
                  setRegion((prev: any) => {
                    prev.splice(idx, 1);
                    return [...prev];
                  });
                } else {
                  setRegion((prev: any) => [...prev, el]);
                }
              }}
            >
              <div
                className={
                  'font-medium f-size-16 line-h-24 no-wrap ' +
                  (region.includes(el) ? 'color-FFFFFF' : 'color-text-tertiary')
                }
              >
                {el}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='padding-10-16 bg-light-gray border-1-171E2610 space-between flex-row center-y'>
        <div className='font-medium f-size-12 line-h-14'>
          <span className='color-primary-400'>{totalCount}</span>개 리스트
        </div>
        <div
          className='padding-8-16 border-1-171E2610 flex-row gap-4 border-radius-100 center-y hover'
          onClick={() => setOnModal(true)}
        >
          <div className='font-medium f-size-12 line-h-14 color-text-primary'>
            {type === 'S' ? orderby1 : orderby}
          </div>
          <img src='/asset/images/arrow/ArrowBottom9.svg' alt='' />
        </div>
      </div>
      <div
        className='padding-x-16 bg-FFFFFF w-100per relative'
        ref={listRef}
        style={
          isMobile(window.innerWidth)
            ? {
                maxHeight: window.innerHeight - 269 + 'px',
                overflowY: !isLogin() ? 'hidden' : 'auto',
                marginBottom: '165px',
              }
            : { maxHeight: window.innerHeight - 350 + 'px', overflowY: 'auto' }
        }
      >
        {!isLogin() ? (
          <div
            style={{
              position: isMobile(window.innerWidth) ? 'absolute' : 'fixed',
              background: '#00000050',
              left: isMobile(window.innerWidth) ? 0 : 73.5,
              width: isMobile(window.innerWidth) ? '100%' : '375px',
              bottom: '0',
              top: isMobile(window.innerWidth) ? 0 : 297,
              zIndex: 999,
              padding: '36px 24px',
              backdropFilter: 'blur(2px)',
              WebkitBackdropFilter: 'blur(2px)',
            }}
          >
            <div
              style={{
                background: '#FFFFFF',
                padding: '24px 16px 16px',
                borderRadius: '16px',
                gap: '12px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className='flex-column gap-8'>
                <div className='font-bold f-size-16 line-h-24 color-text-primary text-center'>
                  로그인 후 확인해보세요!
                </div>
                <div className='font-medium f-size-14 line-h-20 color-text-tertiary text-center'>
                  전체 매물 분석{' '}
                  <span className='color-primary-400'>
                    {totalCount.toLocaleString()}개
                  </span>{' '}
                  리스트를 <br /> 확인하실 수 있습니다.
                </div>
              </div>
              <div className='flex-row gap-8'>
                <div
                  className='padding-y-12 bg-blue-gray-100 center-x w-100per border-radius-8 hover'
                  onClick={() => navigate('/')}
                >
                  <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                    닫기
                  </div>
                </div>
                <div
                  className='padding-y-12 bg-primary-500 center-x w-100per border-radius-8 hover'
                  onClick={() => {
                    sessionStorage.setItem(
                      'pathArea',
                      decodeURI(location.search.split('=')[1] || '')
                    );
                    navigate('/login');
                    sessionStorage.setItem(
                      'pathname',
                      location.pathname.slice(-1) === '/'
                        ? location.pathname.slice(0, -1)
                        : location.pathname
                    );
                    sessionStorage.setItem('type', 'landinfo');
                  }}
                >
                  <div className='font-medium f-size-14 line-h-20 color-FFFFFF'>
                    로그인
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {list.map((el: any) => (
          <Item
            key={el.salesIdx}
            panto={panto}
            setClose={setClose}
            close={close}
            open={(idx: any) => open(idx, type)}
            type={type}
            isFarm={isFarm}
            data={el}
            saleInfo={() => {
              setSaleIdx(el.salesIdx);
              setOnModal3(true);
            }}
            setModalAddress={setModalAddress}
            onClick={() => {
              setSelectedItem(el);
              setTempAddress(el.address);
              setSelectedAuctionNumber(el.auctionNumber);
              setClose(false);

              // ClickSameAddress();
            }}
          />
        ))}
      </div>
      {!list.length ? (
        <div
          className='padding-24-8-56 bg-light-gray flex-column gap-16 center-y'
          style={
            isMobile(window.innerHeight)
              ? {}
              : { width: '100%', height: '100%' }
          }
        >
          <img
            src='/asset/images/check/Exclamation.svg'
            alt=''
            style={{ width: '62px', height: '62px' }}
          />
          <div className='font-medium f-size-14 line-h-20 color-text-primary'>
            {loading ? '데이터 종합 중 입니다.' : '검색 결과가 없습니다.'}
          </div>
          <div
            className='padding-10-16 border-radius-8 flex-row gap-2 center-y hover'
            style={{ border: '1px solid #171e2656' }}
            onClick={() => navigate('')}
          >
            <div className='font-bold f-size-14 line-h-20 color-text-tertiary'>
              전체 매물보기
            </div>
            <img
              src='/asset/images/arrow/ArrowRight16.svg'
              style={{ width: '16px', height: '16px' }}
              alt=''
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Auction;
