import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../../component/function/function';
import { useEffect, useState } from 'react';

const ConsultingPopUp = ({
  setConsultingPopUp,
  consultingPopUp,
  handleDoNotShowToday,
}: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (consultingPopUp) {
      // document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [consultingPopUp]);
  if (window.navigator.userAgent === 'ReactSnap') {
    return <></>;
  }
  return (
    <div
      className='flex-column fixed h-100per z-9999 flex-center w-100per'
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // paddingBottom: `${140}px`,
      }}
      onClick={() => setConsultingPopUp(false)}
    >
      <div
        className={`flex-column browser-border-radius-8 w-100per border-radius-t-l-8 border-radius-t-r-8 ${`bg-FFFFFF absolute bottom-0 left-0`}`}
        style={{
          // top: isMobile(window.innerWidth) ? '15%' : '25%',
          // right: isMobile(window.innerWidth) ? '5%' : '0%',
          top: isMobile(window.innerWidth) ? '11%' : '20%',
          width: '312px',
          height: '550px',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          style={{ height: '510px' }}
          src='/asset/images/banner/consulting_banner.png'
          className='pointer  border-radius-t-l-8 border-radius-t-r-8 '
          onClick={() => {
            setConsultingPopUp(false);
            navigate(
              '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85 '
            );
          }}
        />
        <div className='flex-row space-between w-100per center-y flex-1 padding-8-16'>
          <div
            className='flex-row pointer center-y'
            onClick={() => {
              handleDoNotShowToday();
            }}
          >
            <img
              src={'/asset/images/check/Check24Gray2.svg'}
              style={{ color: '#171E268F' }}
              alt=''
            />
            <div className={`m-b2-14-m ${`color-text-tertiary`}`}>
              오늘 하루 보지 않기
            </div>
          </div>
          <div
            className='flex-row pointer center-y'
            onClick={() => {
              setConsultingPopUp(false);
            }}
          >
            <img src='/asset/images/close/CloseGray24.svg' alt='' />
            <div className='m-b2-14-m color-text-tertiary'>닫기</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultingPopUp;
