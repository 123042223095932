import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, MouseEvent, TouchEvent } from 'react';
import Refresh from '../../../../asset/images/search/refresh.svg';
import Button from '../../../../component/mobile/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../utilities/auth';
import { isMobile, transNum } from '../../../../component/function/function';
import InquiryWrite from '../../../inquiry/InquiryWrite';
import { LoginPopUp2 } from '../../../../component/common/LoginPopUp2';
import UsageArea from '../saleRental2/UsageArea';
import ReportDetail4 from '../../../reportResultSale/components/detail/ReportDetail4';
import ReportDetail3 from '../../../reportResultPrice/components/detail/ReportDetail3';
import PriceService from '../../../../service/price/priceApi';
import { LoadingDot } from '../../../../store/loadingSlice';
import AreaChange from '../../../../asset/images/search/change.svg';
import WarningMark from '../../../../asset/images/new/WarningMark.svg';
import { PointAlert } from '../../../../component/common/pointAlert';
import LoginPopUp from '../../../../component/common/LoginPopUp';
import ReportService from '../../../../service/report/reportApi';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { PointAlert2 } from '../../../../component/common/pointAlert2';
import WarnPoint from '../../../../component/common/warnPoint';
import locationNoImg from '../../../../asset/images/main/locationNoImg.svg';
import SideSearch from '../../../../component/browser/sidebar/Search';
interface ISaleRentalPreview2Data {
  address: string;
  fixMarker: Function;
  currentMarker: any;
  query: string;
  areaList: any;
  currentInfo: any;
  setOnMyLandList: any;
  markerArray: Array<any>;
  setAreaList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setMarkerArray: React.Dispatch<React.SetStateAction<Array<any>>>;
  onMyLandList: boolean;
  setOnReport?: any;
  onReport?: boolean;
  setOnToggle?: any;
  onLoginPopup?: any;
  setPriceReport?: any;
  setClose?: any;
  priceReport?: any;
  close?: any;
  setPriceHouseReportIdx?: any;
}

const HousePreview = (props: ISaleRentalPreview2Data) => {
  const [onLoginPopup, setOnLoginPopup] = useState(false);
  const state = useSelector((state: any) => {
    return state.type;
  });
  const locationHook = useLocation();
  const dispatch = useDispatch();
  const [onInquiry, setOnInquiry] = useState(false);
  const navigate = useNavigate();
  const [onDetail, setOnDetail] = useState(true);
  const [areaindex, setAreaindex] = useState('P');
  const [onWarn, setOnWarn] = useState(false);
  const [onPointAlert, setOnPointAlert] = useState(false);
  const { isLogin } = useAuth();
  const [loading, setLoading] = useState(true);

  const Column2 = ({ title, content, index }: any) => (
    <div
      className={`flex-row  ${
        index !== '3' ? `padding-b-10 border-b-171E26` : `padding-b-10`
      }`}
    >
      <div
        className='w-100per font-medium f-size-16 line-h-24 color-text-tertiary nowrap'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
      </div>

      <div
        className={` font-bold f-size-16 line-h-24 color-text-primary nowrap`}
      >
        {content}
        {index !== '1' && '평'}
      </div>
    </div>
  );

  // console.log(props.currentInfo);
  const [point, setPoint] = useState(0);
  const temp = useRef<HTMLImageElement>(null);
  const [totalData2, setTotalData2] = useState({
    area: 0,
    address: '',
    perMeterEstimatedPrice: 337728,
    perPyeongEstimatedPrice: 1116461.2224,
    estimatedPrice: 572786688,
    category: '답',
    houseTradePrice: 461700000,
    houseTradeDate: '20210421',
    officialPriceList: {
      '2014': 141000,
      '2015': 145300,
      '2016': 147200,
      '2017': 152000,
      '2018': 140500,
      '2019': 146500,
      '2020': 152500,
      '2021': 160700,
      '2022': 182700,
      '2023': 168400,
    },
    difference: 2.0055106888361047,
    surroundings: [
      {
        address: '경기도 안성시 가사동 60-5',
        houseTradePrice: 899934,
        houseTradeDate: '20210421',
        distance: 1.2919436717325758,
      },
      {
        address: '경기도 안성시 가사동 60-6',
        houseTradePrice: 847961,
        houseTradeDate: '20180604',
        distance: 25.54371889347502,
      },
      {
        address: '경기도 안성시 가사동 57-4',
        houseTradePrice: 931032,
        houseTradeDate: '20210320',
        distance: 108.7136306926981,
      },
    ],
    surroundingsAvgPrice: 1198272.4150725163,
    CAGR: 0.019927306710443293,
  });

  const [list, setList] = useState([totalData2]);
  const ItemColumn2 = ({ content }: any) => (
    <div
      className='font-bold f-size-16 line-h-24 color-text-primary nowrap '
      style={{ flex: 0.6 }}
    >
      {content}
    </div>
  );

  const TitleColumn2 = ({ title, children }: any) => (
    <div
      className='font-medium f-size-16 line-h-24 color-text-tertiary'
      style={{ flex: 1, display: 'flex', alignItems: 'center' }}
    >
      {title} {children}
    </div>
  );

  const getPrice = async (query: string) => {
    try {
      const data = await PriceService.house(query);
      setTotalData2(data.result);
      setList(data.resultArr || [data.result]);
    } catch (err) {
      console.log(err);
    }
  };
  const state2 = useSelector((state2: any) => {
    return state2.type;
  });
  const checkReport = async () => {
    try {
      const result = await ReportService.check(
        'AH',
        decodeURI(locationHook.search.split('=')[1])
      );

      if (result.isExist === false) {
        setOnPointAlert(true);
      } else {
        btnHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [dragging, setDragging] = useState(false);
  const [clickPoint, setClickPoint] = useState(0);
  const [clickPoint2, setClickPoint2] = useState(0);
  const [isY, setIsY] = useState(false);
  const [isX, setIsX] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    e.preventDefault();
    e.stopPropagation();

    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'auto';
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      const walk = e.pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const handleTouchDownEvent = (e: TouchEvent<HTMLDivElement>) => {
    setDragging(true);
    setIsY(false);
    setIsX(false);
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'auto';
      setClickPoint(e.touches[0].pageX); // clickPoint는 처음 클릭한 지점
      setClickPoint2(e.touches[0].pageY); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleTouchMoveEvent = (e: TouchEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    if (isY) {
      return;
    }
    if (
      Math.abs(e.touches[0].pageY - clickPoint2) >
        Math.abs(e.touches[0].pageX - clickPoint) &&
      !isX
    ) {
      setIsY(true);
      return;
    }
    setIsX(true);

    e.stopPropagation();
    if (containerRef.current) {
      const walk = e.touches[0].pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const term = isMobile(window.innerWidth) ? window.innerWidth : 360;
  const [contentIdx, setContentIdx] = useState(0);
  const handleMouseEventEnd = (e: MouseEvent<HTMLDivElement> | TouchEvent) => {
    if (!dragging) return;
    setDragging(false);
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';

      containerRef.current.scrollLeft =
        containerRef.current.scrollLeft - scrollLeft === 0
          ? containerRef.current.scrollLeft
          : containerRef.current.scrollLeft - scrollLeft > 0
          ? (containerRef.current.scrollLeft - scrollLeft) % term > 50
            ? containerRef.current.scrollLeft -
              (containerRef.current.scrollLeft % term) +
              term
            : containerRef.current.scrollLeft -
              (containerRef.current.scrollLeft % term)
          : (containerRef.current.scrollLeft - scrollLeft) % term < -50
          ? containerRef.current.scrollLeft -
            (containerRef.current.scrollLeft % term)
          : containerRef.current.scrollLeft +
            (term - (containerRef.current.scrollLeft % term));

      if (containerRef.current.scrollLeft % term === 0) {
        setContentIdx(containerRef.current.scrollLeft / term);
      } else {
        setContentIdx(
          Math.round(
            (containerRef.current.scrollLeft - scrollLeft === 0
              ? containerRef.current.scrollLeft
              : containerRef.current.scrollLeft - scrollLeft > 0
              ? (containerRef.current.scrollLeft - scrollLeft) % term > 50
                ? containerRef.current.scrollLeft -
                  (containerRef.current.scrollLeft % term) +
                  term
                : containerRef.current.scrollLeft -
                  (containerRef.current.scrollLeft % term)
              : (containerRef.current.scrollLeft - scrollLeft) % term < -50
              ? containerRef.current.scrollLeft -
                (containerRef.current.scrollLeft % term)
              : containerRef.current.scrollLeft +
                (term - (containerRef.current.scrollLeft % term))) / term
          )
        );
      }
    }
  };

  const getReportApi = async () => {
    const data = {
      type: 'H',
      address: props.address,
    };

    try {
      dispatch(LoadingDot('W'));
      const result = await ReportService.priceReport(data);

      if (isMobile(window.innerWidth)) {
        navigate(`/price/report/${props.address}`, {
          state: {
            pnuCode: props.currentInfo.pnuCode,
            address: props.address,
            reportIdx: result.reportIdx,
          },
        });
      }
      props.setPriceHouseReportIdx(result.reportIdx);
      props.setPriceReport(true);
      dispatch(LoadingDot('N'));
    } catch (error) {
      dispatch(LoadingDot('N'));
      console.log(error);
    }
  };
  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigatePage = (
    e: MouseEvent<HTMLDivElement> | TouchEvent,
    idx: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollLeft = idx * term;
    }
    setContentIdx(idx);
  };

  const btnHandler = () => {
    if (!isLogin()) {
      notLogin();
    } else {
      navigateToPriceReport(props.address, props.currentInfo.pnuCode);
    }
  };
  const notLogin = () => {
    if (isMobile(window.innerWidth)) {
      navigate('/loginpopup', {
        state: {
          pathname:
            locationHook.pathname.slice(-1) === '/'
              ? locationHook.pathname.slice(0, -1)
              : locationHook.pathname,
          address: props.address,
        },
      });
    } else {
      setOnLoginPopup(true);
    }
  };
  const navigateToPriceReport = (address: any, pnuCode: any) => {
    getReportApi();
    if (isMobile(window.innerWidth)) {
    } else {
      props.setClose(true);
    }
  };
  useEffect(() => {
    dispatch(LoadingDot('Y'));
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      getPrice(decodeURI(locationHook.search.split('=')[1]))
        .then(() => {
          setLoading(false);
          dispatch(LoadingDot('N'));
        })
        .catch((err) => {
          setLoading(false);
          dispatch(LoadingDot('N'));
          console.log(err);
        });
    }
  }, [props.address]);

  useEffect(() => {
    CheckPoint();
  }, []);
  useEffect(() => {
    setContentIdx(0);
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollLeft = 0;
    }
  }, []);
  useEffect(() => {
    dispatch(LoadingDot('Y'));
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      getPrice(decodeURI(locationHook.search.split('=')[1]))
        .then(() => {
          setLoading(false);
          dispatch(LoadingDot('N'));
        })
        .catch((err) => {
          setLoading(false);
          dispatch(LoadingDot('N'));
          console.log(err);
        });
    }
  }, [props.address]);
  // console.log(
  //   'awefawef',
  //   transNum(Number(props.currentInfo.estimatedHousingPrice))
  // );

  const landCatergories = ['전', '답', '과수원', '임야', '대'];

  return (
    <div
      className={`bg-FFFFFF ${
        isMobile(window.innerWidth) ? 'fixed z-1' : 'relative'
      } bottom-0  w-100per border-top-radius-16 flex-column gap-16`}
    >
      {onPointAlert && (
        <PointAlert2
          point={point}
          setOnPointAlert={setOnPointAlert}
          getReportApi={btnHandler}
        />
      )}
      {/* {onPointAlert ? (
        point > 2000 ? (
          <PointAlert2
            point={point}
            setOnPointAlert={setOnPointAlert}
            getReportApi={btnHandler}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )} */}
      {onInquiry && (
        <InquiryWrite address={props.address} setOnInquiry={setOnInquiry} />
      )}
      {onLoginPopup ? (
        <LoginPopUp2
          address={props.address}
          setOnLoginPopup={setOnLoginPopup}
          // setOnLoginPopup={setOnLoginPopup}
          // address={props.address}
          // pathName={(locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname)}
        />
      ) : (
        ''
      )}

      {/*  써머리 랜더링 파트  */}
      {landCatergories.includes(props.currentInfo.category) ? (
        <div
          className={`flex-column ${
            isMobile(window.innerWidth) && `of-scroll`
          }`}
        >
          <div
            className='flex-row center-x hover padding-8 tablet'
            onClick={() => setOnDetail(!onDetail)}
          >
            {isMobile(window.innerWidth) ? (
              <img
                ref={temp}
                src={
                  onDetail
                    ? '/asset/images/arrow/Arrow-Bt-24.svg'
                    : '/asset/images/arrow/ArrowTop24.svg'
                }
                alt=''
              />
            ) : (
              ''
            )}
          </div>

          <div
            className='flex-column transition'
            style={{
              maxHeight: onDetail ? (window.innerHeight * 80) / 100 : 0,
            }}
          >
            <div className='flex-column '>
              <div className='flex-column gap-8 padding-0-16-16'>
                <div className='flex-column gap-8'>
                  <div className='font-bold f-size-16 line-h-24 color-text-primary'>
                    {props.address}
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                    {props.currentInfo.roadNameLocation}
                  </div>
                </div>
                <div className='flex-row space-between '>
                  <div className='flex-row gap-8'>
                    {[
                      { name: '경매물건', value: 'tagAuction' },
                      { name: '공매물건', value: 'tagPublicSale' },
                      { name: '확인매물', value: 'check' },
                      { name: '지자체매물', value: 'tagFarmLandBank' },
                    ].map((el) =>
                      props.currentInfo[el.value] === 'Y' ? (
                        <div
                          className='padding-4-8 border-radius-6 bg-EDF6FF'
                          key={el.name}
                        >
                          <div className='font-medium f-size-12 line-h-14 color-info'>
                            {el.name}
                          </div>
                        </div>
                      ) : (
                        ''
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className='flex-column padding-0-0-24-0 gap-24'>
                <div className='padding-x-16'>
                  <div
                    className='flex-column padding-16  border-radius-8 gap-12'
                    style={{ backgroundColor: '#F7F8F9' }}
                  >
                    <div
                      className='flex-row padding-b-12 border-b-171E26 relative'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div className='w-100per font-medium f-size-16 line-h-24 color-text-tertiary'>
                        AI 주택추정가
                      </div>
                      <div className='flex-column'>
                        <div
                          className={`font-bold f-size-16 line-h-24 color-primary-500 text-right no-wrap`}
                        >
                          {isLogin()
                            ? props.currentInfo.estimatedHousingPrice !== 0
                              ? transNum(
                                  props.currentInfo.estimatedHousingPrice,
                                  true
                                ) + '원'
                              : '심층분석 문의 필요'
                            : '?원'}
                        </div>
                        {!isLogin() && (
                          <div
                            className='speech-bubble7 flex-row pointer'
                            style={{
                              position: 'absolute',
                              backgroundColor: '#3D5066',
                              borderRadius: '8px',
                              right: '60px',
                              padding: '8px',
                              marginLeft: 0,
                              marginBottom: 0,
                              top: '5px',
                            }}
                            onClick={() => {
                              isMobile(window.innerWidth)
                                ? navigate('/loginpopup', {
                                    state: {
                                      pathname:
                                        locationHook.pathname.slice(-1) === '/'
                                          ? locationHook.pathname.slice(0, -1)
                                          : locationHook.pathname,
                                      address: props.address,
                                    },
                                  })
                                : setOnLoginPopup(true);
                            }}
                          >
                            <div className='m-b2-14-m color-FFFFFF'>
                              로그인하고 확인
                            </div>
                            <img src='/asset/images/arrow/ArrowRightWhite20.svg' />
                          </div>
                        )}

                        <div className='font-medium f-size-14 line-h-20 color-text-primary nowrap text-right'>
                          {isLogin()
                            ? props.currentInfo.estimatedHousingPrice !== 0
                              ? areaindex === 'P'
                                ? `${transNum(
                                    props.currentInfo
                                      .estimatedHousingPricePerPyeong,
                                    true
                                  )}원/평`
                                : `${transNum(
                                    props.currentInfo
                                      .estimatedHousingPricePerMeter,
                                    true
                                  )}원/m²`
                              : ''
                            : '?원/평'}
                        </div>
                      </div>
                    </div>
                    <div
                      className='flex-row padding-b-12 border-b-171E26'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div className='w-100per font-medium f-size-16 line-h-24 color-text-tertiary'>
                        실거래가
                      </div>
                      <div className='flex-column'>
                        <div
                          className={`font-bold f-size-16 line-h-24 ${
                            props.currentInfo.houseTradePrice
                              ? 'color-primary-500'
                              : 'color-disabled'
                          }`}
                        >
                          {props.currentInfo.houseTradePrice ? (
                            <div className='font-bold f-size-16 line-h-24 color-text-primary nowrap text-right'>
                              {transNum(props.currentInfo.houseTradePrice) +
                                '원'}
                            </div>
                          ) : (
                            <div className='font-bold f-size-16 line-h-24 color-text-primary nowrap'>
                              거래 이력 없음
                            </div>
                          )}
                        </div>
                        <div className='font-medium f-size-14 line-h-20 color-text-tertiary text-right'>
                          {props.currentInfo.houseTradeDate &&
                            `${props.currentInfo.houseTradeDate.substring(
                              0,
                              4
                            )}.${props.currentInfo.houseTradeDate.substring(
                              4,
                              6
                            )}.${props.currentInfo.houseTradeDate.substring(
                              6,
                              8
                            )}`}
                        </div>
                      </div>
                    </div>{' '}
                    <div className='w-100per flex-row start-y padding-b-12 border-b-171E26'>
                      <TitleColumn2 title='용도지역' />
                      <div className='flex-row center-y '>
                        <ItemColumn2 content={`${props.currentInfo.zoning}`} />
                      </div>
                    </div>
                    <div className='w-100per flex-row start-y padding-b-12 border-b-171E26'>
                      <TitleColumn2 title='토지 면적' />
                      <div className='flex-row center-y gap-4 '>
                        <ItemColumn2
                          content={
                            (areaindex === 'P'
                              ? (
                                  Math.round(
                                    props.currentInfo.landPyeong * 10
                                  ) / 10
                                ).toFixed(1)
                              : (
                                  Math.round(props.currentInfo.landArea * 10) /
                                  10
                                ).toFixed(1)) +
                            (areaindex === 'P' ? ' 평' : ' m²')
                          }
                        />
                        <div
                          className='hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary'
                          onClick={() =>
                            setAreaindex((prev) => (prev !== 'P' ? 'P' : 'M'))
                          }
                        >
                          <img src='/asset/images/arrow/Refresh20.svg' alt='' />
                          {areaindex === 'P' ? `m²` : `평`}
                        </div>
                      </div>
                    </div>
                    {locationHook.pathname === '/house/map' && (
                      <Column2
                        title='주택 면적'
                        content={
                          (areaindex === 'P'
                            ? (
                                Math.round(
                                  props.currentInfo.totalFloorArea * 0.3025 * 10
                                ) / 10
                              ).toFixed(1)
                            : (
                                Math.round(
                                  props.currentInfo.totalFloorArea * 10
                                ) / 10
                              ).toFixed(1)) +
                          (areaindex === 'P' ? ' 평' : ' m²')
                        }
                        index='1'
                      />
                    )}
                    {(props.currentInfo.landPyeong *
                      props.currentInfo.stdFloorAreaRatio) /
                      100 <
                      props.currentInfo.housePyeong && (
                      <div
                        style={{ color: 'red' }}
                        className='f-size-14 line-h-20 font-bold'
                      >
                        건축물 대장 확인 요망
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex-column gap-16'>
                  {!props.onReport &&
                  (locationHook.pathname.slice(-1) === '/'
                    ? locationHook.pathname.slice(0, -1)
                    : locationHook.pathname) === '/house/map' ? (
                    <div className='padding-x-16'>
                      <Button
                        on={
                          (props.currentInfo.landPyeong *
                            props.currentInfo.stdFloorAreaRatio) /
                            100 <
                          props.currentInfo.housePyeong
                            ? false
                            : props.currentInfo.estimatedHousingPrice !== 0
                            ? true
                            : false
                        }
                        description='맞춤설계와 견적받기'
                        onClick={() => {
                          if (
                            (props.currentInfo.landPyeong *
                              props.currentInfo.stdFloorAreaRatio) /
                              100 >=
                            props.currentInfo.housePyeong
                          ) {
                            // setOnPointAlert(true);
                            if (props.currentInfo.estimatedHousingPrice !== 0) {
                              if (isMobile(window.innerWidth)) {
                                if (!isLogin()) {
                                  isMobile(window.innerWidth)
                                    ? navigate('/loginpopup', {
                                        state: {
                                          pathname:
                                            locationHook.pathname.slice(-1) ===
                                            '/'
                                              ? locationHook.pathname.slice(
                                                  0,
                                                  -1
                                                )
                                              : locationHook.pathname,
                                          address: props.query,
                                        },
                                      })
                                    : setOnLoginPopup(true);

                                  return;
                                } else {
                                  // navigate("/house/report?area=" + props.query);
                                  navigate(
                                    '/house/input?address=' + props.query,
                                    { state: { address: props.query } }
                                  );
                                }
                              } else {
                                if (!isLogin()) {
                                  isMobile(window.innerWidth)
                                    ? navigate('/loginpopup', {
                                        state: {
                                          pathname:
                                            locationHook.pathname.slice(-1) ===
                                            '/'
                                              ? locationHook.pathname.slice(
                                                  0,
                                                  -1
                                                )
                                              : locationHook.pathname,
                                          address: props.query,
                                        },
                                      })
                                    : setOnLoginPopup(true);
                                  return;
                                } else {
                                  props.setOnReport(true);
                                }
                              }
                            }
                          }
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {(locationHook.pathname === '/price/map' ||
                    locationHook.pathname === '/price/map/') &&
                  state.priceType === 'house' &&
                  !isLogin() ? (
                    <div className='padding-x-16 mb-16 '>
                      <div className='mb-16'>
                        <Button
                          on={transNum(
                            props.currentInfo.estimatedHousingPrice,
                            true
                          )}
                          description='가격 분석 보고서 보기'
                          onClick={
                            isMobile(window.innerWidth)
                              ? () => {
                                  if (!isLogin()) {
                                    if (isMobile(window.innerWidth)) {
                                      navigate('/loginpopup', {
                                        state: {
                                          pathname:
                                            locationHook.pathname.slice(-1) ===
                                            '/'
                                              ? locationHook.pathname.slice(
                                                  0,
                                                  -1
                                                )
                                              : locationHook.pathname,
                                          address: props.address,
                                        },
                                      });
                                    } else {
                                      setOnLoginPopup(true);
                                    }
                                    return;
                                  } else {
                                    navigate(`/price/report/${props.address}`, {
                                      state: {
                                        pnuCode: props.currentInfo.pnuCode,
                                        address: props.address,
                                      },
                                    });
                                  }
                                }
                              : () => {
                                  if (!isLogin()) {
                                    setOnLoginPopup(true);
                                    return;
                                  } else {
                                    props.setPriceReport(true);
                                    props.setClose(true);
                                  }
                                }
                          }
                        />
                      </div>
                      <div
                        className='flex-center border-radius-8 padding-14-16 border-E5EAF0 hover mb-24'
                        onClick={() => {
                          isMobile(window.innerWidth)
                            ? navigate('/inquiry/write', {
                                state: { address: props.address },
                              })
                            : setOnInquiry(true);
                        }}
                      >
                        <div className='font-bold f-size-16 line-h-24 color-blue-gray-600'>
                          문의하기
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='padding-x-16'>
                      <div
                        className='flex-center border-radius-8 padding-14-16 border-E5EAF0 hover mb-24'
                        onClick={() => {
                          isMobile(window.innerWidth)
                            ? navigate('/inquiry/write', {
                                state: { address: props.address },
                              })
                            : setOnInquiry(true);
                        }}
                      >
                        <div className='font-bold f-size-16 line-h-24 color-blue-gray-600'>
                          문의하기
                        </div>
                      </div>
                    </div>
                  )}

                  {(locationHook.pathname === '/price/map' ||
                    locationHook.pathname === '/price/map/') &&
                  state.priceType === 'house' &&
                  isLogin() ? (
                    <>
                      <ReportDetail4 data={props.currentInfo} house />

                      <div style={{ height: '16px', background: '#F7F8F9' }} />
                      {list.length > 1 ? (
                        <div>
                          <div className='padding-16 flex-row flex-between w-100per border-b-1-171E2610 space-between'>
                            <div
                              style={{
                                width: '32px',
                                height: '32px',
                                background: '#f0f0f0',
                                borderRadius: '999px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={(e) => {
                                if (contentIdx === 0) return;
                                handleNavigatePage(e, contentIdx - 1);
                              }}
                            >
                              <img
                                style={{ width: '8px', height: '14px' }}
                                src='/asset/images/arrow/ArrowLeftPage.svg'
                                alt=''
                              />
                            </div>
                            <div className='web-body-2-bold center-y flex-row gap-8'>
                              <p>{contentIdx + 1}</p>
                              <p>/</p>
                              <p>{list.length}</p>
                            </div>
                            <div
                              style={{
                                width: '32px',
                                height: '32px',
                                background: '#f0f0f0',
                                borderRadius: '999px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              onClick={(e) => {
                                if (contentIdx === list.length - 1) return;
                                handleNavigatePage(e, contentIdx + 1);
                              }}
                            >
                              <img
                                style={{
                                  width: '8px',
                                  height: '14px',
                                  transform: 'rotate(180deg)',
                                }}
                                src='/asset/images/arrow/ArrowLeftPage.svg'
                                alt=''
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        className='flex-row overflow-auto'
                        style={{
                          width: isMobile(window.innerWidth)
                            ? window.innerWidth + 'px'
                            : '360px',
                          overflow: 'hidden',
                          transition: '1s',
                        }}
                        ref={containerRef}
                        onMouseDown={handleMouseDownEvent}
                        onTouchStart={handleTouchDownEvent}
                        onMouseLeave={handleMouseEventEnd}
                        onTouchEnd={handleMouseEventEnd}
                        onMouseUp={(e: any) => {
                          handleMouseEventEnd(e);
                          setDragging(false);
                        }}
                        onTouchMove={handleTouchMoveEvent}
                        onMouseMove={handleMouseMoveEvent}
                      >
                        {list.map((el) => (
                          <div>
                            <div
                              style={{
                                width: isMobile(window.innerWidth)
                                  ? window.innerWidth
                                  : 360,
                              }}
                            />
                            <ReportDetail3 data={el} />
                            <UsageArea data={props.currentInfo} house />
                            <div className='flex-column padding-x-16 mt-24 mb-16'>
                              <Button
                                on={
                                  Number(
                                    props.currentInfo.estimatedHousingPrice
                                  ) !== 0
                                }
                                description='가격 분석 보고서 보기'
                                onClick={() => {
                                  if (
                                    Number(
                                      props.currentInfo.estimatedHousingPrice
                                    ) !== 0
                                  ) {
                                    checkReport();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isMobile(window.innerWidth) ? (
        ''
      ) : (
        <div className='flex-column h-100per center-y bg-light-gray padding-t-50'>
          <img src={locationNoImg} className='w-40 h-40' />
          <div className='w-100per padding-24 center-x font-bold f-size-16 color-text-tertiary text-center line-h-24'>
            해당 주택은 분석대상이 아닙니다.
          </div>
        </div>
      )}
    </div>
  );
};

export default HousePreview;
